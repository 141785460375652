import imgNew1 from "../assets/Images/news/img_new_1.jpg";
import imgNew2 from "../assets/Images/news/img_new_2.jpg";
import imgNew3 from "../assets/Images/news/img_new_3.jpg";
import imgNew4 from "../assets/Images/news/img_new_4.jpg";
import imgNew5 from "../assets/Images/news/img_new_10.jpg";
import imgNew6 from "../assets/Images/news/img_new_6.jpg";
import imgNew7 from "../assets/Images/news/img_new_7.jpg";
import imgNew8 from "../assets/Images/news/img_new_8.jpg";

const listEvent = document.querySelectorAll(".item-benefit");
const imgEvent = document.getElementById("image_event");

const imageSources = [
	imgNew1,
	imgNew2,
	imgNew3,
	imgNew4,
	imgNew5,
	imgNew6,
	imgNew7,
	imgNew8,
];

listEvent.forEach((item, index) => {
	item.addEventListener("mouseenter", () => {
		if (imgEvent && imageSources[index]) {
			imgEvent.src = imageSources[index];
		}
	});
});
